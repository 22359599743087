import React from 'react'
import { findDOMNode } from 'react-dom'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Navigation from '../components/Navigation'
import NoNavigation from '../components/NoNavigation'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'
import $ from 'jquery'
import Clipboard from 'clipboard'
import ReactTooltip from 'react-tooltip'
import ReactGA from 'react-ga'
import Container from '../components/container'
import { AnchorButtonStyle, ButtonMobileCollapse, buttonThemes } from '../components/Button'
import ShareIcon from '../components/icons/ShareIcon'
import TagList from '../components/TagList'
import { Wrapper } from '../components/Wrapper'
import { STYLES } from '../styles/constants'
import playIcon from '../images/video-play-icon.svg'

export const UpdateHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 16px;
  
  .breadcrumb {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
      cursor: pointer;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 20px;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .actions {
    display: flex;
    flex-shrink: 0;

    button {
      margin-left: 16px;
    }
  }
  
  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    margin-top: 62px;
    margin-bottom: 19px;

    .breadcrumb {
      font-size: 24px;
      line-height: 36px;
    }

    .actions {
      button {
        margin-left: 10px;
      }
    }
  }
`;

const UpdateHero = styled.div`
  height: 500px;
  
  .update-hero {
    height: 100%;
    max-width: ${STYLES.MAX_WIDTH};
    margin: 0 auto;
  }

  @media (max-width: ${STYLES.MAX_WIDTH}) {
    height: 480px;
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    height: 380px;
  }

  @media (max-width: ${STYLES.BP_SMALL_MAX}) {
    height: 280px;
  }
`;

const UpdateIntro = styled.div`
  text-align: center;
  margin: 72px auto 16px auto;
  padding: 0 16px;
  max-width: 672px;

  h1 {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    color: inherit;
    line-height: 28px;
  }
  .tag-list{
    margin-bottom:48px;
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    margin: 16px auto;
  }

  @media (max-width: 718px) {
    text-align: left;
    max-width: none;
  }
`;

const UpdateMainContent = styled.div`
  text-align: center;
  margin-bottom: 80px;
  padding: 0 16px;

  h2, h3, p {
    max-width: 640px;
    margin: 0 auto;
  }

  h2 {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
  }

  h3{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    color: inherit;
    line-height: 28px;
    margin-bottom: 28px;

    a {
      color: ${STYLES.COLOR_PN_YELLOW};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }

  a.video-link {
    position: relative;
    display: block;
    max-width: 686px;
    margin: 0 auto 48px auto;

    :after {
      content: '';
      position: absolute;
      top: calc(50% - 32.5px);
      left: calc(50% - 32.5px);
      width: 65px;
      height: 65px;
      background-image: url(${playIcon});
      transition: transform .3s;
      background-size: 100% 100%;
    }

    :before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 97%);
    }

    :hover:after {
      transform: scale(1.1);
    }
  }
  
  img.update-asset {
    max-width: 686px;
    margin: 0 auto 48px auto;
  }

  .buttonContainer {
    text-align: center;
  }

  @media (max-width: ${STYLES.BP_MEDIUM_MAX}) {
    margin-bottom: 52px;
  }

  @media (max-width: 718px) {
    text-align: left;

    h1, p {
      max-width: none;
    }
  }
`;

const UpdatePagination = styled.div`
  background-color: #333333;
  padding: 48px 16px;
  text-align: center;

  .label {
    font-size: 10px;
    color: rgba(255,255,255,0.70);
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .title {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 20px;
    color: #FFFFFF;
    line-height: 32px;
    margin-bottom: 8px;
    display: block;
  }
`;

const customComponent = (node) => {
  if (node.nodeType === 'embedded-asset-block') {
    return `<img src="${node.data.target.fields.file.en_US.url}" class="update-asset" alt=""/>`
  }
  else if(node.nodeType === 'embedded-entry-block') {
    const { fields } = node.data.target
    const videoUrl = fields.url.en_US,
          thumbnail = fields.thumbnail.en_US.fields.file.en_US.url,
          title = fields.title.en_US

    return `<a href="${videoUrl}" class="video-link"><img src="${thumbnail}" alt="${title}"/></a>`
  }
  
  return ''
}

class UpdateTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copyResult: ''
    }
  }

  componentDidMount() {
    const magnificPopupScript = require('../lib/MagnificPopup/jquery.magnific-popup.min.js')
    const magnificPopupStylesheet = require('../lib/MagnificPopup/magnific-popup.css')

    if (document.getElementsByClassName('video-link').length > 0) {
      $('.video-link').magnificPopup({
        type: 'iframe',
        iframe: {
          markup: '<div class="mfp-iframe-scaler">' +
            '<div class="mfp-close"></div>' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            '<div class="mfp-title">Video</div>' +
            '</div>'
        },
        callbacks: {
          markupParse: function (template, values, item) {
            values.title = item.el.attr('title');
          }
        },
        //disableOn: 700, // breakpoint to disable popup

        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: true,
        fixedContentPos: false
      });
    }

    this.clipboard = new Clipboard('#copy-btn', {
        text: () => this.props.location.href
      }
    )

    this.clipboard.on('success', () => this.handleCopySuccess());
    this.clipboard.on('error', () => this.handleCopyError());

    $('a.video-link').on('click', (e) => {
      ReactGA.event({
        category: 'Update',
        action: 'Watch video',
        label: $(e.target).find('img').attr('alt')
      })
    })
  }

  componentWillUnmount() {
    this.clipboard.on('success', () => false);
    this.clipboard.on('error', () => false);
  }

  handleCopySuccess = () => {
    this.setState({ copyResult: 'Copied!' })
    ReactTooltip.show(findDOMNode(this.refs.copyBtn))
    this.removeCopyResult()

    ReactGA.event({
      category: 'Update',
      action: 'Click share button',
      label: this.props.pageContext.slug
    })
  }

  handleCopyError = () => {
    this.setState({ copyResult: 'Sorry, something went wrong.' })
    ReactTooltip.show(findDOMNode(this.refs.copyBtn))
    this.removeCopyResult()
  }

  removeCopyResult = () => {
    setTimeout(() => {
      this.setState({ copyResult: '' })
      ReactTooltip.hide(findDOMNode(this.refs.copyBtn))
    }, 1000)
  }

  goBack = () => {
    ReactGA.event({
      category: 'Update',
      action: 'Click back arrow',
      label: 'from: ' + this.props.pageContext.slug
    })
    window.history.back()
  }

  trackClick = (data) => {
    let event = {
      category: 'Update',
      action: '',
      label: ''
    }

    if (data.type === 'next') {
      event.action = 'View next update'
      event.label = 'next update: ' + data.value.slug
    }
    else if (data.type === 'previous') {
      event.action = 'View previous update'
      event.label = 'previous update: ' + data.value.slug
    }
    else if (data.type === 'contact') {
      event.action = 'Click CTA'
      event.label = 'Contact Us'
    }
    else if (data.type === 'custom') {
      event.action = 'Click CTA'
      event.label = 'Custom'
    }

    ReactGA.event(event)
  }
  
  render() {
    const update = get(this.props, 'data.current')
    const nextUpdate = get(this.props, 'data.next')
    const prevUpdate = get(this.props, 'data.prev')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const backArrowSVG = 
      `<defs>
        <polygon id="shape-a" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"/>
      </defs>
      <use fill="#FFF" xlink:href="#shape-a"/>`
    const renderOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => customComponent(node),
        [INLINES.HYPERLINK]: (node) => `<a href="${node.data.uri}" target="_blank" rel="noopener">${node.content[0].value}</a>`,
        [INLINES.EMBEDDED_ASSET]: (node) => customComponent(node),
        [BLOCKS.EMBEDDED_ENTRY]: (node) => customComponent(node)
      }
    }

    return (
      <Container>
        <Helmet title={`${update.title} | ${siteTitle}`}bodyAttributes={{
              class: update.lightMode ? `light` : 'dark'
          }}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        {update.hideNav && 
        <NoNavigation />
        }
        {!update.hideNav && 
        <Navigation />
        }
        <Wrapper containerPadding>
          <UpdateHeader>
            <div className="breadcrumb">
            </div>
            <div className="actions">
              <ButtonMobileCollapse theme={buttonThemes.secondary} id="copy-btn" data-tip={this.state.copyResult} data-delay-hide="1000" ref="copyBtn"><ShareIcon /><span>Share Link</span></ButtonMobileCollapse>
            </div>
          </UpdateHeader>
        </Wrapper>
        <UpdateHero>
          <Img alt={update.title} sizes={update.heroImage.sizes} className="update-hero"/>
        </UpdateHero> 
        <Wrapper>
          <UpdateIntro>
            <h1 dangerouslySetInnerHTML={{__html: update.headline.replace(/ (?=[^ ]*$)/i, "&nbsp;")}}></h1>
            <TagList tags={update.tags}/>
          </UpdateIntro>
          <UpdateMainContent>
            <div dangerouslySetInnerHTML={{__html: documentToHtmlString(update.main, renderOptions).replace('<p></p>', '').replace(/\n/g, `</br>`)}}></div>
            {update.button === 'Contact Us' &&
              <div className="buttonContainer"><AnchorButtonStyle theme={buttonThemes.primary} href="mailto:krystal.garvin@partnersandnapier.com" onClick={() => this.trackClick({type: 'contact', value: update})}>Contact Us</AnchorButtonStyle></div>
            }
            {update.button === 'Custom' &&
              <div className="buttonContainer"><AnchorButtonStyle theme={buttonThemes.primary} href={update.buttonUrl} rel="noopener" target="_blank" onClick={() => this.trackClick({type: 'custom', value: update})}>{update.buttonText}</AnchorButtonStyle></div>
            }
          </UpdateMainContent>
        </Wrapper>
        { nextUpdate &&
        <UpdatePagination>
          <div className="label">Next</div>
          <Link to={`/update/${nextUpdate.slug}`} className="title" onClick={() => this.trackClick({type: 'next', value: nextUpdate})}>{nextUpdate.title}</Link>
          <TagList tags={nextUpdate.tags} />
        </UpdatePagination>
        }
        { prevUpdate &&
        <UpdatePagination>
          <div className="label">Previous</div>
          <Link to={`/update/${prevUpdate.slug}`} className="title" onClick={() => this.trackClick({type: 'previous', value: prevUpdate})}>{prevUpdate.title}</Link>
          <TagList tags={prevUpdate.tags} />
        </UpdatePagination>
        }
        <ReactTooltip place="top" effect="solid" disable={false} type="light" />
      </Container>
    )
  }
}

export default UpdateTemplate

export const pageQuery = graphql`
  query UpdateBySlug($slug: String!, $nextSlug: String!, $prevSlug: String!) {
    #SITE METADATA
    site {
      siteMetadata {
        title
      }
    }

    #THE UPDATE
    current: contentfulUpdate(slug: { eq: $slug }) {
      title
      headline
      slug
      tags {
        id
        name
      }
      thumbnail {
        sizes(maxWidth: 704, maxHeight: 704) {
          ...GatsbyContentfulSizes
        }
      }
      heroImage {
        sizes(maxWidth: 1200) {
          ...GatsbyContentfulSizes
        }
      }
      # MAIN 
      main {
        nodeType
        content {
          nodeType
          content {
            value
            nodeType
            content {
              value
            }
            data {
              uri
            }
            marks {
              type
            }
          }
          data {
            target {
              fields {
                file {
                  en_US {
                    url
                  }
                }
                url {
                  en_US
                }
                title {
                  en_US
                }
                thumbnail {
                  en_US {
                    fields {
                      file {
                        en_US {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }#/main
    	button
    	buttonText
    	buttonUrl
      hideNav
      lightMode
    }#/contentfulUpdate


    #THE NEXT UPDATE
    next: contentfulUpdate(slug: { eq: $nextSlug }) {
      title
      slug
      tags {
        id
        name
      }
    }#/contentfulUpdate

    #THE PREVIOUS UPDATE
    previous: contentfulUpdate(slug: { eq: $prevSlug }) {
      title
      slug
      tags {
        id
        name
      }
    }#/contentfulUpdate
  }
`